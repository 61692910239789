<template>
  <div>
    <div>
      <heads :more="showMore" :msg="title_msg"></heads>
      <div class="binds">
        <p class="curre">当前绑定手机号</p>
        <p class="numbers">{{ current_mobile.substr(0, 3) + '****' + current_mobile.substr(7, 11) }}</p>
      </div>
      <div class="num_input">
        <div class="num_input_top">
          <p class="qh">+86</p>
          <span class="fg">|</span>
          <input v-model="phone" class="ipt" type="number" placeholder="请输入手机号">
        </div>
        <div>
          <input v-model="yzm" class="ipt" maxlength="4" type="text" placeholder="请输入验证码" @input="changes">
          <span class="fgs">|</span>
          <p class="yzm" @click="get_yzm()">{{ btnText }}</p>
        </div>
      </div>
      <van-button type="danger" style="width: 80%; margin: 20px auto; background: #ff272c" block :disabled="isdisabled" @click="confirm_changes()">确认更改</van-button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import { get_verification_Code, get_phone } from '@/api/my_user.js'
import { sendMobileCode, bindMobile } from '@/api/login.js'
import commontop from '@/compontens/menu.vue'
// import isWxMini from '@/utils/isWechat.js'
import WechatState from '@/utils/isWechatState.js'
import { Toast } from 'vant'
export default {
  components: {
    heads,
    commontop

  },
  data() {
    return {
      yzm: '',
      isdisabled: true,
      title_msg: '手机绑定',
      btnText: '获取验证码',
      phone: '',
      msg: '',
      isshow: false,
      current_mobile: '',
      showMore: true,
      agency_id: '',
      zkb_pro: '',
      isZkb: false
    }
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log(this.agency_id + this.isZkb + '-----------------')
    if (!WechatState.isWechat) {
      // 创建script标签，引入外部文件
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '../../TCaptcha.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  },
  mounted() {
    this.getPhone()
  },
  methods: {
    // 获取当前绑定手机号
    getPhone() {
      get_phone().then(res => {
        console.log(res)
        this.current_mobile = res.result.mobile
      })
    },
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    // 确认更改
    confirm_changes() {
      const cookies = document.cookie
      const arr_cookies = cookies.split(';')
      var str = arr_cookies.forEach(item => {
        var arr = item.split('=')
        if (arr[0] === ' uid') {
          this.msg = arr[1]
        }
      })
      console.log(this.msg)
      const info = {
        uuid: Cookies.get('uuid'),
        mobile: this.phone,
        code: this.yzm,
        access_token: Cookies.get('access_token')
      }
      bindMobile(info).then(res => {
        console.log(res, 'res')
        if (res.errNo == 0) {
          this.getPhone()
        }
      })
      // const params = {
      //   mobile: this.phone,
      //   uid: this.msg,
      //   code: this.yzm
      // }
      // get_verification_Code(params).then(res => {
      //   console.log(res)
      //   this.getPhone()
      // })
    },
    // 监听验证码值发生变化
    changes() {
      if (this.yzm) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    // 获取验证码
    get_yzm() {
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (!reg.test(this.phone)) {
        Toast('手机号格式有误')
        return false
      }
      if (this.btnText !== '获取验证码' && this.btnText !== '重发验证码') { return false }

      if (WechatState.isWechat) {
        // 直接发验证码
        this.sendYzmNoCheck()
      } else {
        // 滑块验证
        this.useTencentCheck()
      }
    },
    // 滑块验证
    useTencentCheck() {
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        console.log(res)
        if (res.ret == 0) {
          console.log(111)
          const randstr = res.randstr
          const ticket = res.ticket
          that.sendYzm(ticket, randstr)
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    // 发送验证码
    sendYzm(ticket, randstr) {
      var params = {
        app_id: 1,
        mobile: this.phone,
        ticket: ticket,
        randstr: randstr,
        code_type: 1
      }
      sendMobileCode(params).then(res => {
        if (res.errNo === 0) {
          this.btnText = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    // 不验证滑块的 直接发验证码
    sendYzmNoCheck() {
      const phone = this.phone
      var params = {
        type: 1,
        mobile: phone,
        code_type: 1,
        client_id: 500
      }
      sendMobileCode(params).then((res) => {
        if (res.errNo === 0) {
          this.btnText = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    // 倒计时
    down_() {
      this.timer = setInterval(() => {
        this.btnText--
        if (this.btnText == 1) {
          clearInterval(this.timer)
          this.timer = null
          this.btnText = '重发验证码'
        }
      }, 1000)
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.binds {
  text-align: center;
  line-height: 60px;
}
.curre {
  padding-top: 30px;
  color: #969696;
}
.numbers {
  letter-spacing: 4px;
}
.num_input {
  width: 680px;
  height: 185px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin: 20px auto;
  background: #fafafa;
}
.num_input_top {
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #ededed;
}
.qh {
  line-height: 90px;
  padding: 0 55px 0 55px;
  float: left;
  color: #484848;
}
.fg {
  color: #ebebeb;
  line-height: 90px;
  float: left;
}

.ipt {
  border: none;
  float: left;
  line-height: 90px;
  margin-left: 40px;
  caret-color: red;
  color: #484848;
  background: #fafafa;
}
.yzm {
  color: #cc1939;
  font-size: 25px;
  line-height: 90px;
  float: right;
  padding-right: 20px;
}
.fgs {
  color: #ebebeb;
  line-height: 90px;
  float: left;
  padding-left: 150px;
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
</style>
